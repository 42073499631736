import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, AlertController, LoadingController, NavController, NavParams, ToastController, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/services/config/config.service';
import { UpiManualPaymentPage } from '../pages/upi-manual-payment/upi-manual-payment.page';
import { LabelService } from 'src/app/services/label/label.service';
import { CartService } from '../services/cart/cart.service';
import { CustomPaymentOptionPage } from '../pages/custom-payment-option/custom-payment-option.page';
import { AdminSettingsService } from '../services/admin-settings/admin-settings.service';
import { StripePage } from '../pages/payment-ui/stripe/stripe.page';
import { PaypalPage } from '../pages/payment-ui/paypal/paypal.page';
import { OrderService } from '../services/order/order.service';
import { SharedService } from '../services/shared/shared.service';
import { CashfreePage } from '../pages/payment-ui/cashfree/cashfree.page';
var AutoConfirmPaymentPage = /** @class */ (function () {
    function AutoConfirmPaymentPage(events, labelService, router, alertController, loadingController, route, navParams, navCtrl, modalController, configService, toastController, cartService, adminSettingsService, orderService, sharedService) {
        this.events = events;
        this.labelService = labelService;
        this.router = router;
        this.alertController = alertController;
        this.loadingController = loadingController;
        this.route = route;
        this.navParams = navParams;
        this.navCtrl = navCtrl;
        this.modalController = modalController;
        this.configService = configService;
        this.toastController = toastController;
        this.cartService = cartService;
        this.adminSettingsService = adminSettingsService;
        this.orderService = orderService;
        this.sharedService = sharedService;
        this.paytmActive = false;
        this.razorpayActive = false;
        this.showLoader = true;
        this.isCod = false;
        this.walletActive = true;
        this.minOrderAmntToUseWallet = 0;
        this.maxWalletAmntPerOrder = 1000;
        this.walletUsed = false;
        this.walletAmount = 0;
        this.envPaytmActive = false;
        this.cashbackAmount = 0;
        this.cashbackBalance = 0;
        this.upiManual = {
            active: false,
            upiId: '',
            qrCode: ''
        };
        this.SHARED_LABELS = {};
        this.AUTO_CONFIRM_PAYMENT_LABELS = {};
        this.isCodAvailableForCoupon = true;
        this.codPercent = 100;
        this.partialPayment = {
            status: false,
            cod: 0,
            online: {
                amount: 0,
                completed: false
            }
        };
        this.paymentType = 'full';
        this.customOption = {
            active: false,
            name: '',
            details: '',
            image: {
                url: ''
            },
            textMandatory: false,
            imageMandatory: false
        };
        this.stripeData = {
            active: false
        };
        this.paymentGateways = ['paypal', 'cashfree', 'ccAvenue'];
        this.paypalObj = {
            active: false
        };
        this.cashfreeObj = {
            active: false
        };
        this.ccAvenueObj = {
            active: false
        };
        this.prodCashfree = false;
        this.extraCharge = {
            cash: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            razorpay: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            paytm: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            stripe: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            paypal: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            cashfree: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 },
            ccAvenue: { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 }
        };
        this.blockUsersCod = {
            active: false,
            users: []
        };
        this.orderData = navParams.get('orderData');
        this.isCodAvailableForCoupon = navParams.get('isCodAvailableForCoupon');
    }
    AutoConfirmPaymentPage.prototype.ngOnInit = function () {
    };
    AutoConfirmPaymentPage.prototype.ionViewDidEnter = function () {
        this.events.publish('admin-settings:getPaytmData');
        this.events.publish('admin-settings:getRazorPayData');
        this.events.publish('admin-settings:getPaymentInfoData');
        this.events.publish('wallet:getUserWalletDetails', this.orderData.userId);
        this.events.publish('wallet:getWalletSettings');
        this.initializeSubscriptions();
        this.currencyCode = this.configService.environment.currencyCode;
        this.taxType = this.configService.environment.taxType;
        this.prodCashfree = this.configService.environment.cashfree.production;
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.AUTO_CONFIRM_PAYMENT_LABELS = this.labelService.labels['AUTO_CONFIRM_PAYMENT'];
        this.getStripeDetails();
        this.getGateways();
    };
    AutoConfirmPaymentPage.prototype.ionViewDidLeave = function () {
        this.removeSubscription();
    };
    AutoConfirmPaymentPage.prototype.removeSubscription = function () {
        this.events.unsubscribe('user:setPaymentModeOfOrderByUserSuccessfully');
        this.events.unsubscribe('admin-settings:publishPaytmData');
        this.events.unsubscribe('admin-settings:publishRazorPayData');
        this.events.unsubscribe('admin-settings:publishPaymentInfoData');
        this.events.unsubscribe('order:ac_modeSetToCashSuccess');
        this.events.unsubscribe('wallet:publishUserWalletDetails');
        this.events.unsubscribe('wallet:publishWalletSettings');
        this.events.unsubscribe('order:ac_completePaymentWithWalletSuccess');
    };
    AutoConfirmPaymentPage.prototype.initializeSubscriptions = function () {
        var _this = this;
        this.events.subscribe('user:setPaymentModeOfOrderByUserSuccessfully', function () {
            _this.loading.dismiss();
            _this.presentAlert("" + _this.AUTO_CONFIRM_PAYMENT_LABELS['payment_mode_set_msg']);
        });
        this.events.subscribe('admin-settings:publishPaytmData', function (data) {
            if (data) {
                _this.paytmActive = false;
                _this.extraCharge.paytm = 'extraChargePaytm' in data ? data.extraChargePaytm : _this.getInitObjForExtraCharge();
            }
        });
        this.events.subscribe('admin-settings:publishRazorPayData', function (data) {
            if (data && data.active && data.id !== '') {
                _this.razorpayActive = data.active;
                _this.razorpayId = data.id;
                _this.extraCharge.razorpay = 'extraChargeRazorpay' in data ? data.extraChargeRazorpay : _this.getInitObjForExtraCharge();
            }
            _this.showLoader = false;
        });
        this.events.subscribe('admin-settings:publishPaymentInfoData', function (data) {
            var isCod = typeof data.isCod !== 'undefined' ? data.isCod : true;
            var extraNoCodChecks = false;
            if (isCod) {
                for (var _i = 0, _a = _this.orderData.products; _i < _a.length; _i++) {
                    var pdt = _a[_i];
                    if ((pdt.hasOwnProperty('orderType') && pdt.orderType === 'membership') || ('isCod' in pdt && !pdt.isCod)) {
                        extraNoCodChecks = true;
                        break;
                    }
                }
            }
            if (extraNoCodChecks || !_this.isCodAvailableForCoupon) {
                isCod = false;
            }
            _this.isCod = isCod;
            _this.upiManual = data.hasOwnProperty('upiManual') ? data.upiManual : _this.upiManual;
            _this.codPercent = 'codPercentage' in data ? data.codPercentage : 100;
            _this.partialPayment.status = _this.codPercent < 100 ? true : false;
            _this.customOption = 'custom' in data ? data.custom : _this.customOption;
            _this.extraCharge.cash = 'extraCharge' in data ? data.extraCharge : _this.getInitObjForExtraCharge();
            _this.isGstApplicable = 'isGstApplicable' in data ? data.isGstApplicable : false;
            _this.blockUsersCod = "blockUsersCod" in data ? data.blockUsersCod : _this.blockUsersCod;
        });
        this.events.subscribe('order:ac_modeSetToCashSuccess', function () {
            _this.loading.dismiss();
            _this.presentAlert("" + _this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']);
            _this.modalDismiss();
        });
        this.events.subscribe('order:ac_completePaymentWithWalletSuccess', function () {
            _this.loading.dismiss();
            _this.presentAlert("" + _this.AUTO_CONFIRM_PAYMENT_LABELS['order_placed_successfully']);
        });
        this.events.subscribe('wallet:publishUserWalletDetails', function (data) {
            if (data) {
                _this.walletBalance = data.wallet ? data.wallet.balance : 0;
                _this.cashbackBalance = data.wallet && data.wallet.cashback && data.wallet.cashback > 0 ? data.wallet.cashback : 0;
            }
        });
        this.events.subscribe('wallet:publishWalletSettings', function (data) {
            // // console.log('publishWalletSettings', data);
            if (!_this.isEmptyObj(data)) {
                _this.walletActive = typeof data.active !== 'undefined' ? data.active : true;
                _this.minOrderAmntToUseWallet = data.minOrderAmnt ? data.minOrderAmnt : 0;
                _this.maxWalletAmntPerOrder = data.maxWalletAmntPerOrder ? data.maxWalletAmntPerOrder : 1000;
            }
        });
    };
    AutoConfirmPaymentPage.prototype.getTotalItems = function () {
        return this.orderData.products.length;
    };
    AutoConfirmPaymentPage.prototype.modalDismiss = function () {
        this.modalController.dismiss();
    };
    AutoConfirmPaymentPage.prototype.completePaymentWithWallet = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alertRes, valid;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])];
                    case 1:
                        alertRes = _a.sent();
                        if (!alertRes) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.checkOrderUpdation()];
                    case 2:
                        valid = _a.sent();
                        if (!valid) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.presentLoading(10000000)];
                    case 3:
                        _a.sent();
                        this.orderData['walletAmount'] = this.walletAmount;
                        this.orderData['cashbackAmount'] = this.cashbackAmount;
                        this.orderData.scheduledDate = this.orderData.scheduledDate ? (this.orderData.scheduledDate).toString() : '';
                        this.events.publish('order:ac_completePaymentWithWallet', this.orderData);
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.onClickPaymentMode = function (mode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alertRes, _i, _a, blockedUser, valid, navigationExtras;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.sharedService.presentAlertConfirm(this.SHARED_LABELS['payment_mode_alert_msg'])];
                    case 1:
                        alertRes = _b.sent();
                        if (!alertRes) {
                            return [2 /*return*/];
                        }
                        if (!(mode === 'cash')) return [3 /*break*/, 5];
                        if (!(this.blockUsersCod && this.blockUsersCod.active)) return [3 /*break*/, 5];
                        if (!(this.orderData.userId && this.blockUsersCod.users.length)) return [3 /*break*/, 5];
                        _i = 0, _a = this.blockUsersCod.users;
                        _b.label = 2;
                    case 2:
                        if (!(_i < _a.length)) return [3 /*break*/, 5];
                        blockedUser = _a[_i];
                        if (!(this.orderData.userId === blockedUser.id)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.sharedService.presentAlert("You cannot use Cash On Delivery, Please choose another payment method")];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                    case 4:
                        _i++;
                        return [3 /*break*/, 2];
                    case 5: return [4 /*yield*/, this.checkOrderUpdation()];
                    case 6:
                        valid = _b.sent();
                        if (!valid) {
                            return [2 /*return*/];
                        }
                        this.orderData.scheduledDate = this.orderData.scheduledDate ? (this.orderData.scheduledDate).toString() : '';
                        this.checkPartialPayment();
                        this.orderData['walletAmount'] = this.walletAmount;
                        this.orderData['cashbackAmount'] = this.cashbackAmount;
                        if (!(mode === 'cash')) return [3 /*break*/, 8];
                        return [4 /*yield*/, this.presentLoading(100000)];
                    case 7:
                        _b.sent();
                        this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.cash);
                        this.events.publish('order:ac_payWithCash', this.orderData);
                        _b.label = 8;
                    case 8:
                        if (mode === 'card' || mode === 'wallet' || mode === 'upi' || mode === 'netbanking') {
                            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.razorpay);
                            this.events.publish('order:ac_payWithRazorPay', this.orderData, this.razorpayId, mode);
                            this.modalDismiss();
                        }
                        if (mode === 'paytm') {
                            this.modalDismiss();
                            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.paytm);
                            navigationExtras = {
                                state: {
                                    orderData: this.orderData
                                }
                            };
                            this.router.navigate(['paytm-initiate-txn'], navigationExtras);
                        }
                        if (mode === 'upiManual') {
                            this.modalDismiss();
                            this.presentUPIModal(this.orderData, this.upiManual);
                        }
                        if (mode === 'custom') {
                            this.modalDismiss();
                            this.presentCustomOptionModal();
                        }
                        if (mode === 'stripe') {
                            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.stripe);
                            this.modalDismiss();
                            this.presentStripeModal();
                        }
                        if (mode === 'paypal') {
                            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.paypal);
                            this.modalDismiss();
                            this.presentPaypalModal();
                        }
                        if (mode === 'cashfree') {
                            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.cashfree);
                            this.modalDismiss();
                            this.presentCashfreeModal();
                            // this.payWithCashfree();
                        }
                        if (mode === 'ccAvenue') {
                            this.orderData['extraChargeOnPayment'] = this.getExtraChargeAmount(this.extraCharge.ccAvenue);
                            this.modalDismiss();
                            this.orderService.createOrderCCAvenue(this.orderData, this.ccAvenueObj);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.getExtraChargeAmount = function (extraChargeObj) {
        var extraCharge = 0;
        extraChargeObj = this.paymentType === 'partial' ? this.extraCharge.cash : extraChargeObj;
        if (Object.keys('extraChargeObj').length && extraChargeObj.charge) {
            if (extraChargeObj.type === 'flat') {
                extraCharge = extraChargeObj.charge;
            }
            else {
                extraCharge = (this.orderData.totalAmountToPaid - (this.orderData.walletAmount + this.orderData.cashbackAmount)) * (extraChargeObj.charge / 100);
                if (extraCharge > extraChargeObj.maxCharge) {
                    extraCharge = extraChargeObj.maxCharge;
                }
            }
            this.orderData.totalAmountToPaid += extraCharge;
            this.calcGst(extraCharge);
        }
        return { charge: extraCharge, name: extraChargeObj.chargeName || '' };
    };
    AutoConfirmPaymentPage.prototype.calcGst = function (extraCharge) {
        var _this = this;
        var allGst = [];
        var gstOnExtraCharge = 0;
        this.orderData.products.map(function (p) {
            if (p.gst && _this.isGstApplicable) {
                allGst.push(p.gst);
            }
        });
        if (allGst.length) {
            var minGst = 0;
            minGst = Math.min.apply(Math, allGst);
            gstOnExtraCharge = (extraCharge - (extraCharge / (1 + (minGst / 100))));
            this.orderData.defaultGst += gstOnExtraCharge;
            this.orderData.deliveryGstObj.extraChargeGst = (this.orderData.deliveryGstObj.extraChargeGst || 0) + gstOnExtraCharge;
        }
    };
    AutoConfirmPaymentPage.prototype.checkOrderUpdation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isProductsUpdated, isQtyAvailable;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.presentLoading(100000)];
                    case 1:
                        _a.sent();
                        console.log('this.orderData.products', this.orderData.products);
                        return [4 /*yield*/, this.cartService.compareCartWithUpdatedCart(this.orderData.products, 'order-summary')];
                    case 2:
                        isProductsUpdated = (_a.sent()).cartUpdated;
                        console.log('isProductsUpdated', isProductsUpdated);
                        isQtyAvailable = true;
                        if (!!isProductsUpdated) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.cartService.inventoryManagement({ products: this.orderData.products, orderId: this.orderData.id })];
                    case 3:
                        isQtyAvailable = _a.sent();
                        console.log('isQtyAvailable', isQtyAvailable);
                        if (isQtyAvailable) {
                            this.loading.dismiss();
                            return [2 /*return*/, true];
                        }
                        _a.label = 4;
                    case 4:
                        if (isProductsUpdated || !isQtyAvailable) {
                            this.loading.dismiss();
                            this.presentAlert(this.SHARED_LABELS['cart_updated_alert'], 'user-cart');
                            return [2 /*return*/, false];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.presentUPIModal = function (order, upiManual) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res, modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.orderService.ac_payWithUPIManual(order)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, this.modalController.create({
                                component: UpiManualPaymentPage,
                                backdropDismiss: false,
                                cssClass: 'custom-modal',
                                componentProps: {
                                    order: res.order,
                                    upiManual: upiManual
                                }
                            })];
                    case 2:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res && res.data && res.data.closed) {
                                _this.navCtrl.navigateRoot(['user-order-history']);
                            }
                            ;
                        });
                        return [4 /*yield*/, modal.present()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.presentCustomOptionModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: CustomPaymentOptionPage,
                            backdropDismiss: false,
                            cssClass: 'custom-modal',
                            componentProps: {
                                order: this.orderData,
                                customOption: this.customOption
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res && res.data && res.data.closed) {
                                _this.navCtrl.navigateRoot(['user-order-history']);
                            }
                            ;
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.isEmptyObj = function (object) {
        for (var key in object) {
            if (object.hasOwnProperty(key))
                return false;
        }
        return true;
    };
    AutoConfirmPaymentPage.prototype.onClickUseWallet = function () {
        if (this.walletUsed) {
            this.walletUsed = false;
            this.walletAmount = 0;
            this.cashbackAmount = 0;
        }
        else {
            this.getUsableWalletAmnt();
            this.walletUsed = true;
        }
    };
    AutoConfirmPaymentPage.prototype.getUsableWalletAmnt = function () {
        var walletUsed = 0;
        var cashbackUsed = 0;
        if (this.orderData.totalAmountToPaid < this.minOrderAmntToUseWallet) {
            walletUsed = this.orderData.totalAmountToPaid >= this.walletBalance ? this.walletBalance : this.orderData.totalAmountToPaid;
            this.walletAmount = walletUsed;
        }
        else {
            cashbackUsed = this.orderData.totalAmountToPaid >= this.cashbackBalance ? this.cashbackBalance : this.orderData.totalAmountToPaid;
            cashbackUsed = cashbackUsed > this.maxWalletAmntPerOrder ? this.maxWalletAmntPerOrder : cashbackUsed;
            this.cashbackAmount = cashbackUsed;
            if (this.orderData.totalAmountToPaid - this.cashbackAmount > 0) {
                walletUsed = (this.orderData.totalAmountToPaid - this.cashbackAmount) >= this.walletBalance ? this.walletBalance : (this.orderData.totalAmountToPaid - this.cashbackAmount);
                this.walletAmount = walletUsed;
            }
        }
    };
    AutoConfirmPaymentPage.prototype.checkPartialPayment = function () {
        var partial = tslib_1.__assign({}, this.partialPayment);
        if (this.paymentType === 'partial') {
            partial.status = true;
        }
        else {
            partial.status = false;
        }
        this.orderData['partialPayment'] = partial;
    };
    AutoConfirmPaymentPage.prototype.setPaymentType = function (type) {
        this.paymentType = type;
        if (type === 'partial') {
            this.partialPayment['cod'] = parseFloat((this.getFinalAmount() * (this.codPercent / 100)).toFixed(2));
            this.partialPayment['online']['amount'] = parseFloat((this.getFinalAmount() - this.partialPayment['cod']).toFixed(2));
        }
    };
    AutoConfirmPaymentPage.prototype.getFinalAmount = function () {
        return this.orderData.totalAmountToPaid - (this.walletAmount + this.cashbackAmount);
    };
    AutoConfirmPaymentPage.prototype.presentToast = function (msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toast;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.toastController.create({
                            message: msg,
                            duration: 2000,
                        })];
                    case 1:
                        toast = _a.sent();
                        toast.present();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.presentAlert = function (msg, page) {
        if (page === void 0) { page = 'order-successful'; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var alert;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.alertController.create({
                            message: msg,
                            backdropDismiss: false,
                            buttons: [{
                                    text: this.SHARED_LABELS['ok'],
                                    handler: function () {
                                        if (_this.modalController) {
                                            _this.modalDismiss();
                                        }
                                        _this.navCtrl.navigateRoot([page]);
                                    }
                                }]
                        })];
                    case 1:
                        alert = _a.sent();
                        return [4 /*yield*/, alert.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.presentLoading = function (duration) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loadingController.create({
                                message: this.SHARED_LABELS['please_wait'],
                                duration: duration ? duration : 5000
                            })];
                    case 1:
                        _a.loading = _b.sent();
                        return [4 /*yield*/, this.loading.present()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.getStripeDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.adminSettingsService.getStripeData()];
                    case 1:
                        _a.stripeData = _b.sent();
                        this.extraCharge.stripe = 'extraChargeStripe' in this.stripeData ? this.stripeData.extraChargeStripe : this.getInitObjForExtraCharge();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.presentStripeModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: StripePage,
                            backdropDismiss: false,
                            cssClass: 'custom-modal',
                            componentProps: {
                                orderData: this.orderData,
                                stripeData: this.stripeData,
                                autoConfirm: true
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res && res.data && res.data.closed) {
                                _this.navCtrl.navigateRoot(['user-order-history']);
                            }
                            ;
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.presentPaypalModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: PaypalPage,
                            backdropDismiss: false,
                            cssClass: 'custom-modal',
                            componentProps: {
                                orderData: this.orderData,
                                autoConfirm: true,
                                paypalObj: this.paypalObj
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        modal.onDidDismiss().then(function (res) {
                            if (res && res.data && res.data.closed) {
                                _this.navCtrl.navigateRoot(['user-order-history']);
                            }
                            ;
                        });
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.getGateways = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _i, _a, paymentGateway, paymentGatewayData;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _i = 0, _a = this.paymentGateways;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        paymentGateway = _a[_i];
                        return [4 /*yield*/, this.adminSettingsService.getPaymentGateway(paymentGateway)];
                    case 2:
                        paymentGatewayData = _b.sent();
                        if (paymentGatewayData && paymentGatewayData.active) {
                            console.log('paymentGateway : ', paymentGatewayData);
                            if (paymentGateway == 'paypal') {
                                this.paypalObj = paymentGatewayData;
                                this.extraCharge.paypal = 'extraChargePaypal' in this.paypalObj ? this.paypalObj.extraChargePaypal : this.getInitObjForExtraCharge();
                            }
                            if (paymentGateway == 'cashfree') {
                                this.cashfreeObj = paymentGatewayData;
                                this.extraCharge.cashfree = 'extraChargeCashfree' in this.cashfreeObj ? this.cashfreeObj.extraChargeCashfree : this.getInitObjForExtraCharge();
                            }
                            if (paymentGateway == 'ccAvenue') {
                                this.ccAvenueObj = paymentGatewayData;
                                this.extraCharge.ccAvenue = 'extraChargeCCAvenue' in this.ccAvenueObj ? this.ccAvenueObj.extraChargeCCAvenue : this.getInitObjForExtraCharge();
                            }
                        }
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // async payWithCashfree(){
    //     console.log('this.orderData:',this.orderData );
    //     await this.orderService.createOrderCashfree(this.orderData, true);
    // }
    AutoConfirmPaymentPage.prototype.presentCashfreeModal = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var modal;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.modalController.create({
                            component: CashfreePage,
                            cssClass: 'custom-modal',
                            componentProps: {
                                orderData: this.orderData,
                                autoConfirm: true
                            }
                        })];
                    case 1:
                        modal = _a.sent();
                        return [4 /*yield*/, modal.present()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AutoConfirmPaymentPage.prototype.getInitObjForExtraCharge = function () {
        return { charge: 0, type: 'flat', chargeName: '', maxCharge: 0 };
    };
    return AutoConfirmPaymentPage;
}());
export { AutoConfirmPaymentPage };
